const data = [
  {
    responsive_id: "",
    id: 95,
    full_name: "Edwina Ebsworth",
    post: "Human Resources Assistant",
    email: "eebsworth2m@sbwire.com",
    city: "Puzi",
    start_date: "09/27/2018",
    salary: 19586.23,
    age: "27",
    experience: "2 Years",
    status: 1,
  },
  {
    responsive_id: "",
    id: 1,
    full_name: "Korrie O'Crevy",
    post: "Nuclear Power Engineer",
    email: "kocrevy0@thetimes.co.uk",
    city: "Krasnosilka",
    start_date: "09/23/2016",
    salary: 23896.35,
    age: "61",
    experience: "1 Year",
    status: 2,
  },
  {
    responsive_id: "",
    id: 7,
    full_name: "Eileen Diehn",
    post: "Environmental Specialist",
    email: "ediehn6@163.com",
    city: "Lampuyang",
    start_date: "10/15/2017",
    salary: 18991.67,
    age: "59",
    experience: "9 Years",
    status: 3,
  },
  {
    responsive_id: "",
    id: 11,
    full_name: "De Falloon",
    post: "Sales Representative",
    email: "dfalloona@ifeng.com",
    city: "Colima",
    start_date: "06/12/2018",
    salary: 19252.12,
    age: "30",
    experience: "0 Year",
    status: 4,
  },
  {
    responsive_id: "",
    id: 3,
    full_name: "Stella Ganderton",
    post: "Operator",
    email: "sganderton2@tuttocitta.it",
    city: "Golcowa",
    start_date: "03/24/2018",
    salary: 13076.28,
    age: "66",
    experience: "6 Years",
    status: 5,
  },
  {
    responsive_id: "",
    id: 5,
    full_name: "Harmonia Nisius",
    post: "Senior Cost Accountant",
    email: "hnisius4@gnu.org",
    city: "Lucan",
    start_date: "08/25/2017",
    salary: 10909.52,
    age: "33",
    experience: "3 Years",
    status: 2,
  },
  {
    responsive_id: "",
    id: 6,
    full_name: "Genevra Honeywood",
    post: "Geologist",
    email: "ghoneywood5@narod.ru",
    city: "Maofan",
    start_date: "06/01/2017",
    salary: 17803.8,
    age: "61",
    experience: "1 Year",
    status: 1,
  },
  {
    responsive_id: "",
    id: 4,
    full_name: "Dorolice Crossman",
    post: "Cost Accountant",
    email: "dcrossman3@google.co.jp",
    city: "Paquera",
    start_date: "12/03/2017",
    salary: 12336.17,
    age: "22",
    experience: "2 Years",
    status: 2,
  },
];

export default data;
